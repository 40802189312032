//@author: travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { AttributeI } from "../../../pojo/AttributeI";
import { DropDownItem } from "../../../pojo/DropDownItem";

@TypeManagerDecorator("s25-custom-attr-dropdown-adv-search")
@Component({
    selector: "s25-custom-attr-dropdown-adv-search",
    template: `
        <div class="ngSingleSelectOptions ngRelative">
            <button class="aw-button aw-button--primary ngFloatRight" (click)="done()">Done</button>
            @if (this.items) {
                <s25-generic-dropdown
                    [(chosen)]="this.chosen"
                    [hasFav]="true"
                    [onSelect]="this.onSelect"
                    [items]="this.items"
                    [staticItems]="this.staticItems"
                    [autoOpen]="this.autoOpen"
                    [alwaysOpen]="this.alwaysOpen"
                    [hideSearch]="this.hideSearch"
                    [focusFirst]="false"
                    [searchEnabled]="false"
                    [apiBean]="this.apiBean"
                    [serverSide]="this.serverSide"
                    [placeholder]="'Select a Custom Attribute'"
                    [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                    (chosenChange)="onAttributeSet($event)"
                >
                </s25-generic-dropdown>
                @if (showOperatorDropdown) {
                    <s25-search-operators-dropdown
                        [attributeTypeId]="this.atrb.custAtrbType"
                        [(chosen)]="this.chosenOperator"
                        (chosenChange)="onOperatorSet($event)"
                    >
                    </s25-search-operators-dropdown>
                }
                @if (chosenOperator) {
                    <div>
                        @if (chosenOperator.val == 50) {
                            <div>
                                @if (this.atrb.custAtrbType === 2) {
                                    <div>
                                        <s25-organization-dropdown
                                            [(chosen)]="this.atrb.custAtrbValue"
                                        ></s25-organization-dropdown>
                                    </div>
                                }
                                @if (this.atrb.custAtrbType === 3) {
                                    <div>
                                        <s25-contact-dropdown
                                            [(chosen)]="this.atrb.custAtrbValue"
                                        ></s25-contact-dropdown>
                                    </div>
                                }
                                @if (this.atrb.custAtrbType === 4) {
                                    <div>
                                        <s25-location-dropdown
                                            [(chosen)]="this.atrb.custAtrbValue"
                                        ></s25-location-dropdown>
                                    </div>
                                }
                                @if (this.atrb.custAtrbType === 6) {
                                    <div>
                                        <s25-resource-dropdown
                                            [(chosen)]="this.atrb.custAtrbValue"
                                        ></s25-resource-dropdown>
                                    </div>
                                }
                            </div>
                        }
                        @if (showTextField) {
                            <div>
                                <input type="text" [(ngModel)]="this.atrb.custAtrbValue" class="c-input" />
                            </div>
                        }
                    </div>
                }
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})

/**
 * Dropdown used by advanced search for selecting a custom attribute. It has hree phases:
 * 1) Choose a Custom attribute
 * 2) Choose an operation type for the search (isTrue, isEqual, isGreaterThan, exists,etc...)
 * 3) May asks for matching value
 *     - Dropdown:
 *         - CustomAttributeTypeis an object type (contact, Org etc.) AND
 *         - matcher: is equal
 *     - text field:
 *         -  matcher is (50-55, 58) (not "is True", "is False", "does not exist", "exists")
 * Click done to convert results
 **/
export class S25CustomAttrDropdownAdvSearchComponent extends S25DropdownAbstract implements OnInit {
    @Input() itemTypeId: number;
    @Input() queryParams: any[] = [];
    @Output() queryParamsChange = new EventEmitter<DropDownItem[]>();
    atrb: any;

    showOperatorDropdown = false;
    count: boolean = false;
    typeBase: number;
    chosenOperator: DropDownItem;
    showTextField: boolean;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    onAttributeSet(data: any) {
        this.showOperatorDropdown = false;
        // forces operator picker to update
        this.chosenOperator = {};
        this.queryParams = [];
        this.cd.detectChanges();

        this.atrb = data;
        this.showOperatorDropdown = true;
        this.cd.detectChanges();
    }

    /*
    determine which field should be down
        - dropdowns for contacts etc.
        - none for true/false, exsits/does not exist (56/57)
        - otherwise text field
    */
    onOperatorSet(data: any) {
        this.atrb.custAtrbValue = null;
        if (!S25Util.isNumeric(this.atrb.custAtrbType) && (data.val === 58 || (50 <= data.val && data.val <= 55))) {
            this.showTextField = true;
        } else {
            this.showTextField = false;
        }
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.typeBase = this.itemTypeId * 100; //1 to 100, 4 to 400, etc
        SearchCriteriaService.getCustomAttributeItems(this.itemTypeId).then((data: AttributeI[]) => {
            this.zone.run(() => {
                data = data || [];
                data.sort(S25Util.shallowSort("itemName"));
                this.items = data.map((obj) => {
                    return {
                        txt: obj.itemName,
                        val: obj.itemId,
                        custAtrbType: obj.itemTypeId,
                    };
                });
                this.onLoad && this.onLoad();
            });
        });
    }

    /*
        Converts the selection to a string usable by seriesql ('Atrb Name', 'operator name', 'Atrb_type', 'term')
    */
    done() {
        let custAtrb = { txt: this.atrb.txt, val: this.atrb.val }; //cust atrb info
        // let relationship = {txt: $element.find("option:selected").text().trim(), val: this.atrb.relationshipTypeId}; //qualfier info
        let operator = { txt: this.chosenOperator.itemName, val: this.chosenOperator.itemName };
        let custAtrbType = { txt: this.atrb.custAtrbType, val: this.atrb.custAtrbType };

        //custAtrbValue only set for org/space/event/resource and text-value cust atrb (NOT boolean and NOT exists/not-exists)
        let isValueObj = this.atrb.custAtrbValue && this.atrb.custAtrbValue.txt;
        let value = null;
        if (isValueObj) {
            value = { txt: this.atrb.custAtrbValue.txt, val: this.atrb.custAtrbValue.val }; //search value info
        } else {
            value = { txt: this.atrb.custAtrbValue, val: this.atrb.custAtrbValue }; //search value info
        }

        this.queryParams = [custAtrb, operator, custAtrbType];
        S25Util.isDefined(this.atrb.custAtrbValue) && this.queryParams.push(value);
        this.queryParamsChange.emit(this.queryParams);
    }
}
