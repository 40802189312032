import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { CustomAttributes } from "../../../pojo/CustomAttributes";

@TypeManagerDecorator("s25-search-operators-dropdown")
@Component({
    selector: "s25-search-operators-dropdown",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                #genericChild
                [items]="this.items"
                [(chosen)]="this.chosen"
                [onSelect]="this.onSelect"
                [placeholder]="''"
                [searchEnabled]="false"
                (chosenChange)="onChosenChange($event)"
            ></s25-generic-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25SearchOperatorsDropdownComponent extends S25DropdownAbstract implements OnInit, OnChanges {
    @Input() attributeTypeId?: string | number;

    constructor(private elementRef: ElementRef) {
        super();
        this.elementRef.nativeElement.angBridge = this;
    }

    operators: DropDownItem[] = [
        { itemName: "is True", val: Operators.IsTrue },
        { itemName: "is False", val: Operators.IsFalse },
        { itemName: "is equal to", val: Operators.Equals },
        { itemName: "is less than or equal to", val: Operators.LTE },
        { itemName: "is greater than or equal to", val: Operators.GTE },
        { itemName: "starts with", val: Operators.StartsWith },
        { itemName: "is earlier than or equal to", val: Operators.Earlier },
        { itemName: "is later than or equal to", val: Operators.Later },
        { itemName: "does not exist", val: Operators.NotExists },
        { itemName: "exists", val: Operators.Exists },
        { itemName: "contains", val: Operators.Contains },
    ];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.attributeTypeId && !changes.attributeTypeId.firstChange) {
            this.chosen = null;
            this.getOperators();
        }
    }

    ngOnInit(): void {
        this.getOperators();
    }

    getOperators() {
        if (this.attributeTypeId) {
            let itemIds = [];
            switch (this.attributeTypeId) {
                case CustomAttributes.Type.Boolean:
                    itemIds.push(Operators.IsTrue, Operators.IsFalse, Operators.Exists, Operators.NotExists);
                    break;
                case CustomAttributes.Type.Image:
                case CustomAttributes.Type.LargeText:
                    itemIds.push(Operators.Exists, Operators.NotExists);
                    break;
                case CustomAttributes.Type.Organization:
                case CustomAttributes.Type.Contact:
                case CustomAttributes.Type.Location:
                case CustomAttributes.Type.Resource:
                    itemIds.push(Operators.Equals, Operators.Exists, Operators.NotExists);
                    break;
                case CustomAttributes.Type.FileReference:
                case CustomAttributes.Type.String:
                    itemIds.push(
                        Operators.Equals,
                        Operators.StartsWith,
                        Operators.Contains,
                        Operators.Exists,
                        Operators.NotExists,
                    );
                    break;
                case CustomAttributes.Type.Date:
                case CustomAttributes.Type.Time:
                case CustomAttributes.Type.Datetime:
                    itemIds.push(
                        Operators.Equals,
                        Operators.Earlier,
                        Operators.Later,
                        Operators.Exists,
                        Operators.NotExists,
                    );
                    break;
                case CustomAttributes.Type.Integer:
                case CustomAttributes.Type.Float:
                case CustomAttributes.Type.Duration:
                    itemIds.push(
                        Operators.Equals,
                        Operators.LTE,
                        Operators.GTE,
                        Operators.Contains,
                        Operators.Exists,
                        Operators.NotExists,
                    );
                    break;
                default:
                    itemIds.push(Operators.Equals);
                    break;
            }

            this.items = itemIds.map((id) => {
                return S25Util.array.getByProp(this.operators, "val", id);
            });
        } else {
            this.items = this.operators;
        }
    }
}

const Operators = {
    IsTrue: 1050,
    IsFalse: 2050,
    Equals: 50,
    LTE: 51,
    GTE: 52,
    StartsWith: 53,
    Earlier: 54,
    Later: 55,
    NotExists: 56,
    Exists: 57,
    Contains: 58,
} as const;
