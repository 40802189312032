import { NgModule } from "@angular/core";
import { S25DurationComponent } from "./s25.duration.component";

@NgModule({
    imports: [],
    declarations: [S25DurationComponent],
    exports: [S25DurationComponent],
    providers: [S25DurationComponent],
})
export class S25DurationModule {}
