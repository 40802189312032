//@author: mandy
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { DropdownApi } from "../dropdown.api";

@TypeManagerDecorator("s25-ng-subfolders-dropdown")
@Component({
    selector: "s25-ng-subfolders-dropdown",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [placeholder]="''"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-generic-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25SubfolderDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() items: any;
    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    refresh = (items: any[]) => {
        this.items = items;
        DropdownApi.refresh(this.elementRef.nativeElement, this.items);
        this.cd.detectChanges();
    };
}
