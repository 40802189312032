//@author: travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { RateService } from "../../../services/rate.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-formulas-dropdown")
@Component({
    selector: "s25-formulas-dropdown",
    template: `
        @if (this.items) {
            <div>
                <s25-generic-dropdown
                    [chosen]="this.chosen"
                    [hasFav]="false"
                    [onSelect]="this.onSelect"
                    [items]="this.items"
                    [staticItems]="this.staticItems"
                    [autoOpen]="this.autoOpen"
                    [alwaysOpen]="this.alwaysOpen"
                    [hideSearch]="this.hideSearch"
                    [focusFirst]="true"
                    [searchEnabled]="true"
                    [apiBean]="this.apiBean"
                    [serverSide]="this.serverSide"
                    [placeholder]="'Select a Pricing Formula'"
                    [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                >
                </s25-generic-dropdown>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25FormulasDropdownComponent extends S25DropdownAbstract implements OnInit {
    constructor(
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        RateService.getRateSchedules().then((data) => {
            data.sort(S25Util.shallowSort("type_id", true));
            this.items = data.map((rate: any) => {
                return {
                    itemName: rate.rate_name,
                    itemId: rate.rate_id,
                    groupName: rate.type,
                    grp: rate.type,
                    itemTypeId: rate.type_id,
                };
            });

            // this.items = [
            //     {itemName: "first",
            //     itemId: 1,
            //     itemTypeId: 2,
            // }];

            this.onLoad && this.onLoad();
            this.cd.detectChanges();
        });
    }
}
