import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { Season, SeasonsService } from "../../student-housing/seasons/seasons.service";
import { S25Util } from "../../../util/s25-util";
import { UserprefService } from "../../../services/userpref.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { S25ItemI } from "../../../pojo/S25ItemI";

@TypeManagerDecorator("s25-ng-season-dropdown")
@Component({
    selector: "s25-ng-season-dropdown",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [placeholder]="'Select a Season'"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-generic-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25SeasonDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() selectedSeason: Season;
    @Output() selectedSeasonChange = new EventEmitter<Season>();

    seasons: Season[];
    dateFormat: string;

    onChosenChange(data: any) {
        super.onChosenChange(data);
        this.selectedSeason = null;
        let matches = this.seasons.filter((s) => s.seasonId === this.chosen.itemId);
        if (matches.length) {
            this.selectedSeason = matches[0];
        }
        this.selectedSeasonChange.emit(this.selectedSeason);
    }

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    seasonToItem = (season: Season): S25ItemI => {
        return {
            itemId: season.seasonId,
            itemName:
                season.code +
                ": " +
                S25Datefilter.transform(season.housingStartDate, this.dateFormat) +
                " - " +
                S25Datefilter.transform(season.housingEndDate, this.dateFormat),
            itemDesc: season.descr,
        };
    };

    ngOnInit() {
        S25Util.all({
            dateFormat: UserprefService.getS25Dateformat(),
            seasons: SeasonsService.getSeasons(),
        }).then((resp) => {
            this.zone.run(() => {
                this.dateFormat = resp.dateFormat;
                this.seasons = resp.seasons || [];
                if (this.selectedSeason) {
                    this.chosen = this.seasonToItem(this.selectedSeason);
                }
                this.items = this.seasons.map((s: Season) => {
                    return this.seasonToItem(s);
                });
                this.onLoad && this.onLoad();
                this.cd.detectChanges();
            });
        });
    }
}
