//@author: travis
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { DropdownDataUtil } from "../dropdown.data.util";

@TypeManagerDecorator("s25-resource-dropdown")
@Component({
    selector: "s25-resource-dropdown",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="true"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="'Select a Resource'"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-generic-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25ResourceDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() customFilterValue: string;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
    ) {
        super();
    }

    ngOnInit() {
        if (this.chosen) {
            this.chosen.itemTypeId = 6;
        }
        SearchCriteriaService.getResources(-999, 0, 999, null, true, null, this.customFilterValue).then((data) => {
            this.zone.run(() => {
                this.staticItems = (data && data.searchCriteria && data.searchCriteria.item) || [];
                this.serverSide = DropdownDataUtil.getServerSideModel(
                    "getResources",
                    this.elementRef.nativeElement,
                    this.customFilterValue,
                );
                this.items = S25Util.deepCopy(this.staticItems);
                this.onLoad && this.onLoad();
            });
        });
    }
}
