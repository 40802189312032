//@author: travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { jSith } from "../../../util/jquery-replacement";
import { DropdownDataUtil } from "../dropdown.data.util";

@TypeManagerDecorator("s25-contact-dropdown")
@Component({
    selector: "s25-contact-dropdown",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="true"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="true"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="'Select a Contact'"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-generic-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25ContactDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() r25UserOnly?: boolean | any = false;
    @Input() usernameModel?: boolean | any = false;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        if (this.chosen) {
            this.chosen.itemTypeId = 3;
        }
        let customFilterValue = S25Util.toBool(this.r25UserOnly) ? "&is_r25user=1" : "";
        customFilterValue += S25Util.toBool(this.usernameModel) ? "&itemName=username" : "";
        SearchCriteriaService.getContacts(-999, 0, 999, null, true, null, customFilterValue).then((data) => {
            this.zone.run(() => {
                this.staticItems = this.staticItems || [];
                let staticCopy = [].concat(this.staticItems);
                let favorites = (data && data.searchCriteria && data.searchCriteria.item) || [];

                jSith.forEach(favorites, (favKey, fav) => {
                    let inStatic = false;

                    jSith.forEach(staticCopy, (itemKey, item) => {
                        if (parseInt(fav.itemId) === parseInt(item.itemId)) {
                            inStatic = true;
                            item.favorite = "T";
                        }
                    });

                    if (!inStatic) {
                        if (staticCopy.length && staticCopy[0].grp) {
                            fav.grp = "Starred";
                        }
                        this.staticItems.push(fav);
                    }
                });

                this.serverSide = DropdownDataUtil.getServerSideModel(
                    "getContacts",
                    this.elementRef.nativeElement,
                    customFilterValue,
                );
                this.items = S25Util.deepCopy(this.staticItems);
                this.onLoad && this.onLoad();
                this.cd.detectChanges();
            });
        });
    }
}
