import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-duration")
@Component({
    selector: "s25-ng-duration",
    template: `
        <span class="label">Duration:</span>
        <span class="duration">{{ duration }}</span>
    `,
    styles: `
        :host {
            border: 1px solid rgba(0, 0, 0, 0.15);
            width: 210px;
            padding: 0.8em 0;
            background: #fff;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        .duration {
            font-weight: bold;
        }

        ::ng-deep #s25.nm-party--on s25-ng-duration {
            background-color: #28272c;
            border-color: rgba(255, 255, 255, 0.24);
        }
    `,
})
export class S25DurationComponent implements OnChanges {
    @Input() start: Date;
    @Input() end: Date;

    duration: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.start || changes.end) this.duration = this.calculateDuration(this.start, this.end);
    }

    calculateDuration(start: Date, end: Date) {
        if (!start || !end) return;
        const minutes = S25Util.date.diffMinutes(start, end);
        return S25Util.getDurationString(minutes);
    }
}
